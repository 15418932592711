import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { Wrapper } from '../components/ui';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ImageSlider from '../components/ImageSlider';
import ModularBlocks from '../components/ModularBlocks';

const IndexPage = () => {
  const {
    datoCmsHome: {
      seoMetaTags,
      title,
      bannerOverline,
      bannerHeading,
      bannerText,
      bannerLinks,
      imageSlider,
      imageSliderLogo,
      modularBlocksTop,
      modularBlocksBottom,
    },
  } = useStaticQuery(graphql`
    query IndexPageQuery {
      datoCmsHome {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
        title
        bannerOverline
        bannerHeading
        bannerText
        bannerLinks {
          id
          text
          page {
            ...LinkFragment
          }
        }
        imageSlider {
          ...ImageSliderImagesFragment
        }
        imageSliderLogo {
          ...ImageSliderLogoFragment
        }
        modularBlocksTop {
          ...CtaStripModularBlockFragment
          ...ContentCardsCarouselModularBlockFragment
          ...ContentCardsModularBlockFragment
          ...ContentModularBlockFragment
          ...FullWidthImageModularBlockFragment
          ...ImageContentCardsModularBlockFragment
          ...ImageCarouselModularBlockFragment
          ...StatisticsModularBlockFragment
        }
        modularBlocksBottom {
          ...CtaStripModularBlockFragment
          ...ContentCardsCarouselModularBlockFragment
          ...ContentCardsModularBlockFragment
          ...ContentModularBlockFragment
          ...FullWidthImageModularBlockFragment
          ...ImageContentCardsModularBlockFragment
          ...ImageCarouselModularBlockFragment
          ...StatisticsModularBlockFragment
        }
      }
    }
  `);

  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <main>
        <Banner
          overline={bannerOverline}
          heading={bannerHeading.replace(/(<p>|<\/p>)/g, '') || title}
          text={bannerText}
          links={bannerLinks}
          isHomePage={true}
        />
        <Wrapper altTheme={true} isHomePage={true}>
          <ImageSlider
            items={imageSlider}
            logo={imageSliderLogo}
            isHomePage={true}
          />
          <ModularBlocks
            items={modularBlocksTop}
            altTheme={true}
            isHomePage={true}
          />
        </Wrapper>
        <ModularBlocks items={modularBlocksBottom} isHomePage={true} />
      </main>
    </Layout>
  );
};

export default IndexPage;
